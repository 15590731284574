'use client'

import { decode } from 'html-entities'
import { type KeyboardEvent, useState } from 'react'
import type { EmailFormFields } from 'react-mailchimp-subscribe'

const NewsletterForm = ({
  status,
  message,
  onValidated,
}: {
  status: 'error' | 'success' | 'sending' | null
  message: string | Error | null
  onValidated: (formData: EmailFormFields) => void
}) => {
  const [error, setError] = useState('')
  const [email, setEmail] = useState<string>()

  const handleFormSubmit = (event?: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<Element>) => {
    setError('')
    event?.preventDefault()

    if (!email) {
      setError('Please enter a valid email address')
      return null
    }

    const isFormValidated = onValidated({ EMAIL: email })
    return email && email.indexOf('@') > -1 && isFormValidated
  }

  const handleInputKeyEvent = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleFormSubmit(event)
    }

    setError('')
  }

  return (
    <>
      <form className="mt-6 sm:flex sm:max-w-md">
        <label htmlFor="footer-email-address" className="sr-only">
          Email address
        </label>
        <input
          id="footer-email-address"
          onChange={(event) => setEmail(event?.target?.value ?? '')}
          type="email"
          onKeyUp={(event) => handleInputKeyEvent(event)}
          required
          className="w-full rounded-md border-0 bg-zinc-900 px-3 py-3 text-white placeholder:text-zinc-700 sm:w-64"
          placeholder="Enter your email"
        />
        <div className="mt-2 sm:ml-2 sm:mt-0">
          <button
            onClick={(event) => handleFormSubmit(event)}
            type="submit"
            className="h-full w-full rounded-md bg-zinc-100 px-3 py-3 text-sm text-black shadow-sm"
          >
            Subscribe
          </button>
        </div>
      </form>

      <div className="text-bold px-2 pt-4">
        {status === 'sending' && <div className="text-primary">Sending...</div>}
        {status === 'error' || error ? <div className="text-red-500">An Error occured, Try again!</div> : null}
        {status === 'success' && !error && message && (
          <div className="text-green-200" dangerouslySetInnerHTML={{ __html: decode(message.toString()) }} />
        )}
      </div>
    </>
  )
}

export default NewsletterForm
